import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ClientPortalService } from '../../../services/clientPortalService/client-portal.service';
@Component({
  selector: 'rcp-page-heading',
  standalone: true,
  imports: [],
  templateUrl: './page-heading.component.html',
  styleUrl: './page-heading.component.scss',
})
export class PageHeadingComponent {
  @Input() pageHeading: string;
  @Input() preHeading: string;
  @Input() postHeading: string;
  heading;
  clientPortalService = inject(ClientPortalService);
  router = inject(Router);
  ngOnInit(): void {
    if (this.pageHeading) {
      this.heading = this.pageHeading;
    } else {
      this.getHeadingFromURL();
    }
  }
  getHeadingFromURL() {
    const currentCompany = this.clientPortalService.getActiveClient();
    const routeInfo = this.clientPortalService.getActiveRouteInfo(
      currentCompany,
      this.router.url
    );
    if (routeInfo) {
      this.heading = routeInfo.title;
    } else {
      this.heading = '';
      console.error(
        `page heading is not shown as route info not avialable for ${currentCompany} and route ${this.router.url}`
      );
    }
  }
}
